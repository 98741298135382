'<template>
  <div id="checkout-slide">
    <div v-if="!hideCart">
      <div class="checkout-intro-container">
        <slide-heading :heading="slide.heading" class="intro-heading mt-0" />

        <p class="intro-content">
          {{ slide.description }}
        </p>
      </div>

      <div class="checkout-cart-container">
        <div>
          <checkout-cart
            class="checkout-cart"
            :bus="bus"
            :show-tos-url="true"
            @submit="nextSlide"
            @register-company-order-verification="foreignQualificationPitch = true"
          />
        </div>
      </div>
    </div>

    <h5 v-else class="w-100 text-center">
      There are no products currently available for checkout.
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'CheckoutSlide',
  components: {
    CheckoutCart:              () => import('@/components/shared/CheckoutCart.vue'),
    SlideHeading:              () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      bus: new Vue(),
      hideCart: true,
      foreignQualificationPitch: false,
      registerACompanyProductInCart: false,
    }
  },
  computed: {
    ...mapGetters('invoices', [
      'invoices',
    ]),
    ...mapGetters('checkout', [
      'cartEmpty',
      'cartItems',
    ]),
    ...mapGetters('stageline', [
      'firstIncompleteSlideId',
      'getStageByIdOrName',
    ]),
  },
  mounted() {
    this.hideCart = this.cartEmpty
    this.registerACompanyProductInCart = this.findCartProductByTitle('register a company')

    this.bus.$on('register-company-order-verification', () => {
      this.foreignQualificationPitch = true
    })
  },
  methods: {
    findCartProductByTitle(subString) {
      if(this.cartEmpty) return false

      return !!this.cartItems.find(
        ci => ci.title.toLowerCase().includes(subString)
      )
    },
    async nextSlide() {
      if (this.registerACompanyProductInCart && this.foreignQualificationPitch) await this.navigateToVTOPage()

      setTimeout(() => {
        this.$emit('next-slide')
      }, 3000)
    },
    async navigateToVTOPage() {
      const stage = this.getStageByIdOrName('finalize_legal_documents')
      const slideId = this.firstIncompleteSlideId({ type: 'stage', typeId: stage.id })

      await this.$router.push({ name: 'verify-order',
        params: {
          from: 'foreignQualification',
          slideId: slideId,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#checkout-slide {
  .checkout-cart-container {
    border-radius: 4px;

    .card {
      padding-top: 0 !important;
      margin: 0 !important;
    }
    .checkout-intro-container {
      padding-bottom: 2.0em;
      padding-top: 0 !important;
      margin: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  #checkout-slide {
    .checkout-cart {
      min-width: 80%;
      margin: 0 15%;
    }
  }
}

@media only screen and (max-width: 375px) {
  #checkout-slide {
    .checkout-cart {
      min-width: 95%;
      margin: 0;
    }
  }
}
</style>
